import { DeviceFingerprint } from "./DeviceFingerprint";
import { cyrb53 } from "./EncryptDecrypt";
import { getCanvasFingerprint } from "./GenerateCanvasFingerprint";
import { GenerateTheAudioFingerPrint } from "./GenerateTheAudioPrints";

export const getDeviceFingerprint = async (): Promise<DeviceFingerprint> => {
  const getUniqueStoredId = (): { epoch: number; id: string } => {
    var udid = localStorage.getItem("udid");
    var id = null;
    var epoch = null;
    if (!udid) {
      const values = window.crypto.getRandomValues(new Uint8Array(64));
      id = hashArray(values);
      epoch = new Date().getTime();
      localStorage.setItem("udid", JSON.stringify({ id, epoch }));
    } else {
      try {
        var { id, epoch } = JSON.parse(udid);
        const currentTime = new Date().getTime();
        if (currentTime - epoch > 1000 * 60 * 60 * 24 * 30) {
          localStorage.removeItem("udid");
          id = getUniqueStoredId();
        }
      } catch (error) {
        localStorage.removeItem("udid");
        id = getUniqueStoredId();
      }
    }
    return { epoch, id };
  };

  const getDeviceSignature = (uniqueStoredId: string): Promise<string> => {
    function collectDeviceInfo() {
      return {
        udid: uniqueStoredId,
        userAgent: navigator.userAgent,
        timezoneOffset: new Date().getTimezoneOffset(),
        language: navigator.language,
      };
    }

    // Function to hash data using SHA-256

    return new Promise((resolve, reject) => {
      const deviceInfo = collectDeviceInfo();
      hashData(deviceInfo)
        .then((hash) => {
          resolve(hash);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getDeviceId = (): Promise<string> => {
    /**
     * @return {Promise} - a frequency number 120.256896523
     * @reference - https://fingerprintjs.com/blog/audio-fingerprinting/
     */
    const getTheAudioPrints = new Promise((resolve, reject) => {
      GenerateTheAudioFingerPrint.run(function (fingerprint: any) {
        resolve(fingerprint);
      });
    });

    /**
     *
     * @param {null}
     * @return {Promise<string>} - and sha512 hashed string
     */
    const DevicePrints: Promise<string> = new Promise((resolve, reject) => {
      getTheAudioPrints
        .then((audioChannelResult) => {
          let fingerprint =
            window.btoa(audioChannelResult as string) + getCanvasFingerprint();
          // using btoa to hash the values to looks better readable
          resolve(cyrb53(fingerprint, 0) as unknown as string);
        })
        .catch(() => {
          try {
            // if failed with audio fingerprint then resolve only with canvas fingerprint
            resolve(cyrb53(getCanvasFingerprint()).toString());
          } catch (error) {
            reject("Failed to generate the finger print of this browser");
          }
        });
    });
    return DevicePrints;
  };

  const uniqueStoredId = getUniqueStoredId();
  const deviceId = await getDeviceId();
  const deviceSignature = await getDeviceSignature(uniqueStoredId.id);
  return { deviceId, uniqueStoredId: uniqueStoredId.id, deviceSignature };

  async function hashData(data: any) {
    const buffer = new TextEncoder().encode(JSON.stringify(data));
    const hashBuffer = await crypto.subtle.digest("SHA-256", buffer);
    return hashArray(hashBuffer);
  }

  function hashArray(buffer: ArrayBuffer) {
    const hashArray = Array.from(new Uint8Array(buffer));
    return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
  }
};
