import { FC, useState } from "react";
import { IoWarning } from "react-icons/io5";
import { BsEye, BsEyeSlash } from "react-icons/bs";

interface Props {
  password: string;
  handleChangePassword: (e: string) => void;
  errorPassword: string | null;
  inputLabel?: string;
  disableShowPassword?: boolean;
  className?: string;
  maxLength?: number;
  minLength?: number;
  autoFocus?: boolean;
  handleEnter?: () => void;
}

export const PasswordField: FC<Props> = ({
  password,
  handleChangePassword,
  errorPassword,
  inputLabel,
  disableShowPassword,
  className,
  maxLength,
  minLength,
  autoFocus,
  handleEnter,
}) => {
  const randomId = Math.random().toString(36).substring(7);
  const floatingId = `floating_outlined_${randomId}`;

  const [passwordType, setPasswordType] = useState<string>("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div className={className}>
      <div className="relative mb-2">
        <input
          maxLength={maxLength ?? 60}
          value={password}
          onChange={(e) => {
            handleChangePassword(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (handleEnter && password.length > (minLength ?? 0)) {
                handleEnter();
              }
            }
          }}
          type={passwordType}
          id={floatingId}
          placeholder=" "
          ref={(input) => {
            autoFocus && input && input.focus();
          }}
          className={`block shadow leading-10 w-full text-md text-gray-900 bg-transparent rounded-md border-1.5 ${
            errorPassword ? "border-red-500" : "border-gray-[#7c879f]"
          } appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
        />
        {disableShowPassword ? null : (
          <span className="absolute top-5 right-5">
            <button
              onClick={() => togglePassword()}
              className="p-1 text-[17px]"
            >
              {passwordType === "password" ? <BsEye /> : <BsEyeSlash />}
            </button>
          </span>
        )}
        <label
          htmlFor={floatingId}
          className={`absolute text-md duration-300 transform -translate-y-[20px] translate-x-[5px] scale-75 top-2 z-10 origin-[0] ${
            errorPassword ? "text-red-500" : "text-gray-500"
          } bg-white px-1 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 left-1
            peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-[20px] peer-focus:translate-x-[5px]`}
        >
          {inputLabel ? inputLabel : "Password"}
        </label>
      </div>

      {errorPassword ? (
        <p className="text-red-500 px-1 pb-2 text-base flex gap-1 items-center">
          <span className="text-[20px]">
            <IoWarning />
          </span>
          {errorPassword}
        </p>
      ) : (
        <div></div>
      )}
    </div>
  );
};
