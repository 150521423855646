import { useEffect } from "react";

interface XeroSignInButtonProps {
  label: string;
}

export function XeroSigninRedirect() {
  const clientId = "F48F73A29A7E41F283094C212D27A0F3";
  const redirectUri = "https://sso.tradepeg.com/xero/callback";
  const state = "sign-up";

  const redirect_uri = encodeURIComponent(redirectUri);
  const client_id = clientId;

  var signInUrl = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=openid profile email`;

  if (state) {
    signInUrl += `&state=${state}`;
  }

  return signInUrl;
}

const XeroSignInButton: React.FC<XeroSignInButtonProps> = ({ label }) => {
  const signInUrl = XeroSigninRedirect();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://edge.xero.com/platform/sso/xero-sso.js";
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <span
      data-xero-sso
      data-href={signInUrl}
      data-label={label}
      data-size="large"
      data-height="50"
      data-no-track="true"
      data-theme="light"
      data-border-radius="10"
      data-layout="block"
      data-box-shadow="0 2px 2px rgba(0,0,0,0.2)"
    ></span>
  );
};

export default XeroSignInButton;
