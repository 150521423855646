import { FC, useState } from "react";
import { handleSuccess } from "../../utils/swal";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { parseError } from "../../ApiClientHelper";
import { AuthenticationService, UserProfileBase } from "../../api-client";

interface EditProfileProps {
  profile: UserProfileBase;
  handleGoBack: () => void;
  setError: (value: string | null) => void;
  setProfile: (value: UserProfileBase) => void;
}

export const UpdateProfile: FC<EditProfileProps> = ({
  profile,
  handleGoBack,
  setError,
  setProfile,
}) => {
  const [firstName, setFirstName] = useState<string>(profile.nameFirst);
  const [lastName, setLastName] = useState<string>(profile.nameLast);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSave = async (): Promise<void> => {
    try {
      setLoading(true);
      const body = {
        nameFirst: firstName,
        nameLast: lastName,
      };

      const response = await AuthenticationService.postAuthenticationProfile({
        headers: {
          Authorization: `${localStorage.getItem("sso_token")}`,
        },
        body,
      });

      if (response.error) {
        setError(parseError(response.error));
        return;
      }

      handleSuccess("Profile updated successfully");
      setProfile(body);
      handleGoBack();
    } catch (error) {
      const errorString = parseError(error);
      if (errorString !== null) {
        setError(errorString);
      }
    } finally {
      setLoading(false);
    }
  };

  const isButtonDisabled = (): boolean => {
    return (
      (profile.nameFirst === firstName && profile.nameLast === lastName) ||
      loading ||
      firstName === "" ||
      lastName === ""
    );
  };

  return (
    <div>
      <button onClick={handleGoBack} className="mb-4">
        <HiArrowNarrowLeft
          size={30}
          className="text-gray-500 transform transition-transform duration-300 group-hover:scale-105"
        />
      </button>
      <div>
        <p className="text-gray-600 mb-5 font-semibold">Update your profile</p>
        <div className="relative mb-4">
          <input
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            type="text"
            id="firstName"
            placeholder=" "
            className={`block shadow leading-10 w-full text-md text-gray-900 bg-transparent rounded-md border-1.5 border-gray-[#7c879f] appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
          />

          <label
            htmlFor="firstName"
            className={`absolute text-md text-gray-500 duration-300 transform -translate-y-[20px] translate-x-[5px] scale-75 top-2 z-10 origin-[0] bg-white px-1  peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-[20px] peer-focus:translate-x-[5px] left-1`}
          >
            First Name
          </label>
        </div>
        <div className="relative mb-2">
          <input
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            type="text"
            id="lastName"
            placeholder=" "
            className={`block shadow leading-10 w-full text-md text-gray-900 bg-transparent rounded-md border-1.5 border-gray-[#7c879f] appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
          />
          <label
            htmlFor="lastName"
            className={`absolute text-md text-gray-500 duration-300 transform -translate-y-[20px] translate-x-[5px] scale-75 top-2 z-10 origin-[0] bg-white px-1 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-[20px] peer-focus:translate-x-[5px] left-1`}
          >
            Last Name
          </label>
        </div>
        <button
          disabled={isButtonDisabled()}
          onClick={handleSave}
          className="sign-in-button mt-3"
        >
          Save
        </button>
      </div>
    </div>
  );
};
