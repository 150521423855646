export const GenerateTheAudioFingerPrint = (function () {
  var context: OfflineAudioContext;
  var currentTime: number;
  var oscillator: OscillatorNode | null = null;
  var compressor: DynamicsCompressorNode;
  var fingerprint: string | null = null;
  var callback: Function | null = null;

  function run(cb: Function, debug = false) {
    callback = cb;

    try {
      setup();

      if (compressor === null || oscillator === null || context === null) {
        return;
      }

      oscillator?.connect(compressor);
      compressor?.connect(context.destination);

      oscillator?.start(0);
      context?.startRendering();

      if (context?.oncomplete === null) {
        context.oncomplete = onComplete;
      }
    } catch (e) {
      if (debug) {
        throw e;
      }
    }
  }

  function setup(): boolean {
    setContext();
    if (context === null) return false;
    currentTime = context?.currentTime;
    setOscillator();
    setCompressor();
    return true;
  }

  function setContext() {
    var audioContext = window.OfflineAudioContext;
    context = new audioContext(1, 44100, 44100);
  }

  function setOscillator() {
    if (context === null) return;
    oscillator = context.createOscillator();
    oscillator.type = "triangle";
    oscillator.frequency.setValueAtTime(10000, currentTime);
  }

  function setCompressor() {
    compressor = context.createDynamicsCompressor();

    compressor.threshold.setValueAtTime(-50, currentTime);
    compressor.knee.setValueAtTime(40, currentTime);
    compressor.ratio.setValueAtTime(12, currentTime);
    compressor.attack.setValueAtTime(0, currentTime);
    compressor.release.setValueAtTime(0.25, currentTime);
  }

  function onComplete(event: OfflineAudioCompletionEvent) {
    generateFingerprints(event);
    compressor.disconnect();
  }

  function generateFingerprints(event: OfflineAudioCompletionEvent) {
    var output: string = "";
    for (var i = 4500; 5e3 > i; i++) {
      var channelData = event.renderedBuffer.getChannelData(0)[i];
      output += Math.abs(channelData);
    }

    fingerprint = output.toString();

    if (typeof callback === "function") {
      return callback(fingerprint);
    }
  }

  return {
    run: run,
  };
})();
