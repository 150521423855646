import Swal from "sweetalert2";
import { NewMethodSetup } from "./NewMethodSetup";
import { useEffect, useState, FC } from "react";
import { FaTrash, FaPlus } from "react-icons/fa6";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { parseError } from "../../ApiClientHelper";
import { swalStyle, handleSuccess } from "../../utils/swal";
import { AuthenticationService, MfaMethodsResponse } from "../../api-client";

interface MfaMethodsProps {
  handleGoBack: () => void;
  setError: (value: string | null) => void;
}

export const MfaMethods: FC<MfaMethodsProps> = ({ handleGoBack, setError }) => {
  const [mfaMethods, setMfaMethods] = useState<MfaMethodsResponse | undefined>(
    undefined
  );

  const [addNew, setAddNew] = useState<boolean>(false);
  const [addedNew, setAddedNew] = useState<boolean>(false);

  const getMfaMethods = async (): Promise<void> => {
    try {
      const response =
        await AuthenticationService.getAuthenticationProfileMfaMethods({
          headers: {
            Authorization: `${localStorage.getItem("sso_token")}`,
          },
        });

      if (response.error) {
        setError(parseError(response.error));
        return;
      }

      setMfaMethods(response.data?.data);
    } catch (error: any) {
      const errorString = parseError(error);
      if (errorString !== null) {
        setError(errorString);
      }
    }
  };

  const handleDeleteMethod = async (id: string): Promise<void> => {
    try {
      const response =
        await AuthenticationService.deleteAuthenticationProfileMfaMethodsId({
          headers: {
            Authorization: `${localStorage.getItem("sso_token")}`,
          },
          path: {
            id,
          },
        });

      if (response.error) {
        setError(parseError(response.error));
        return;
      }

      handleSuccess("Method removed successfully");
      getMfaMethods();
    } catch (error: any) {
      const errorString = parseError(error);
      if (errorString !== null) {
        setError(errorString);
      }
    }
  };

  const handleDefaultMethod = async (
    id: string | null | undefined
  ): Promise<void> => {
    try {
      if (!id) {
        setError("Method id is missing");
        return;
      }
      const response =
        await AuthenticationService.putAuthenticationProfileMfaMethodsIdDefault(
          {
            headers: {
              Authorization: `${localStorage.getItem("sso_token")}`,
            },
            path: {
              id,
            },
          }
        );

      if (response.error) {
        setError(parseError(response.error));
        return;
      }
      getMfaMethods();
    } catch (error: any) {
      const errorString = parseError(error);
      if (errorString !== null) {
        setError(errorString);
      }
    }
  };

  const confirmDeleteMethod = (id: string | null | undefined): void => {
    if (!id) {
      setError("Method id is missing");
      return;
    }
    Swal.fire({
      title: `Are you sure you want to remove this method?`,
      showCancelButton: true,
      position: "top",
      cancelButtonText: "Confirm",
      confirmButtonText: "Cancel",
      allowEnterKey: false,
      ...swalStyle,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.close();
      } else if (result.isDismissed) {
        handleDeleteMethod(id);
      }
    });
  };

  const confirmDefaultMethod = (id: string | null | undefined): void => {
    if (!id) {
      setError("Method id is missing");
      return;
    }
    Swal.fire({
      title: `Are you sure you want set this method as default?`,
      showCancelButton: true,
      position: "top",
      cancelButtonText: "Confirm",
      confirmButtonText: "Cancel",
      allowEnterKey: false,
      allowOutsideClick: false,
      backdrop: false,
      customClass: {
        popup: "pb-2 w-fit",
        actions: "!mt-0",
        confirmButton: " !bg-gray-500 border-none !shadow focus:!shadow py-1",
        cancelButton: "!bg-green-500 border-none !shadow focus:!shadow py-1",
        title: "text-xl font-normal pt-2 px-4 ",
        htmlContainer: " !text-gray-500 !text-base !mt-1 mb-0",
      },
      showClass: {
        popup: "animate__animated animate__slideInDown animate__faster",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp animate__faster",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.close();
      } else if (result.isDismissed) {
        handleDefaultMethod(id);
      }
    });
  };

  const handleNewMethodAdded = (): void => {
    getMfaMethods();
    setAddedNew(true);
  };

  useEffect(() => {
    getMfaMethods();
  }, []);

  return (
    <div>
      <button onClick={handleGoBack} className="mb-4">
        <HiArrowNarrowLeft
          size={30}
          className="text-gray-500 transform transition-transform duration-300 group-hover:scale-105"
        />
      </button>

      <p className="text-gray-600 mb-3 font-semibold">
        Two-factor authentication
      </p>

      {!addNew && (
        <p className="text-gray-600 mb-3 text-sm">
          Two-factor authentication adds an additional layer of security to your
          account by requiring more than just a password to sign in.
        </p>
      )}

      {mfaMethods?.available?.length && !addNew && (
        <div className="mb-3">
          <button
            onClick={() => {
              setError(null);
              setAddNew(true);
            }}
            className="manage-profile-button mt-3"
          >
            <FaPlus size={16} className="inline mr-1.5" />
            Add new
          </button>
        </div>
      )}

      {addNew ? (
        <NewMethodSetup
          availableMethods={mfaMethods?.available}
          setError={setError}
          handleNewMethodAdded={handleNewMethodAdded}
          addNew={addNew}
        />
      ) : (
        <div className="flex flex-col gap-1.5">
          {mfaMethods?.used &&
            mfaMethods.used.map((method) => (
              <div
                key={method.id}
                className="flex flex-col gap-1 border rounded-md shadow p-1 px-2 pr-4 relative"
              >
                <p className="font-semibold">{method.title}</p>
                <p>{method.description}</p>
                <div className="mt-2">
                  <label
                    id="default"
                    className="inline-flex items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      value=""
                      className="sr-only peer "
                      checked={method.isPrimary}
                      disabled={method.isPrimary}
                      onChange={() => confirmDefaultMethod(method.id)}
                      id="default"
                    />
                    <div
                      className={`relative w-9 h-5 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:rounded-full after:h-4 after:w-4 ${
                        method.isPrimary ? "cursor-not-allowed" : ""
                      } peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:transition-all peer-checked:bg-[#03c160]`}
                    ></div>
                    <span className="ms-1.5 text-sm text-gray-700">
                      Default
                    </span>
                  </label>
                </div>
                {method.canDelete && (
                  <button
                    className="absolute right-3 bottom-3"
                    onClick={() => confirmDeleteMethod(method.id)}
                  >
                    <FaTrash className="text-red-500" />
                  </button>
                )}
              </div>
            ))}
        </div>
      )}

      {addNew && !addedNew && (
        <button
          onClick={() => {
            setError(null);
            setAddNew(false);
          }}
          className="back-button mt-3 "
        >
          Cancel
        </button>
      )}
    </div>
  );
};
