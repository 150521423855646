import {
  AuthenticationService,
  MfaDeviceSetupResponse,
} from "../../api-client";
import ReactLoading from "react-loading";
import "react-phone-input-2/lib/style.css";
import { MfaMethod } from "../../api-client";
import PhoneInput from "react-phone-input-2";
import { useEffect, useState, FC } from "react";
import { parseError } from "../../ApiClientHelper";
import { SubmitButton } from "../fields/SubmitButton";
import { OtpFieldInput } from "../fields/OtpFieldInput";
interface NewMethodProps {
  availableMethods: MfaMethod[] | null | undefined;
  setError: (value: string | null) => void;
  handleNewMethodAdded: () => void;
  addNew: boolean;
}

export const NewMethodSetup: FC<NewMethodProps> = ({
  availableMethods,
  setError,
  handleNewMethodAdded,
  addNew,
}) => {
  const [newMethodType, setNewMethodType] = useState<MfaMethod>();
  const [info, setInfo] = useState<string>("");

  const [mfaResponse, setMfaResponse] = useState<string | null>(null);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [recoveryCodes, setRecoveryCodes] = useState<
    string[] | null | undefined
  >(null);

  const [mfaSetupResponse, setMfaSetupResponse] = useState<
    MfaDeviceSetupResponse | undefined | null
  >(null);

  const getToken = (): string | null => {
    if (sessionStorage.getItem("sso_token")) {
      return sessionStorage.getItem("sso_token");
    }
    const tkn = localStorage.getItem("sso_token");
    if (tkn) {
      return tkn;
    }
    return null;
  };

  const authorizationToken = getToken();

  const mfaSetupRequestToken = async (mfa: MfaMethod | null): Promise<void> => {
    setError(null);
    try {
      setLoadingSubmit(true);
      debugger;
      const response =
        await AuthenticationService.postAuthenticationProfileMfaSetup({
          headers: {
            Authorization: `${authorizationToken}`,
          },
          body: {
            mfaType: mfa?.type || newMethodType?.type!,
            info,
          },
        });
      if (response.error) {
        setError(parseError(response.error));
        return;
      }
      setMfaSetupResponse(response.data!.data);
    } catch (error) {
      const errorString = parseError(error);
      if (errorString !== null) {
        setError(errorString);
      }
    } finally {
      setLoadingSubmit(false);
    }
  };

  const mfaSetupConfirm = async (): Promise<void> => {
    setError(null);
    try {
      setLoadingSubmit(true);

      if (!mfaResponse) {
        setError("Please enter the code");
        return;
      }

      const response =
        await AuthenticationService.postAuthenticationProfileMfaSetupConfirm({
          headers: {
            Authorization: `${authorizationToken}`,
          },
          body: {
            mfaType: newMethodType?.type!,
            token: mfaSetupResponse!.token!,
            response: mfaResponse,
          },
        });
      if (response.error) {
        setError(parseError(response.error));
        return;
      }
      setRecoveryCodes(response.data?.data?.recoveryCodes);
      handleNewMethodAdded();
    } catch (error) {
      const errorString = parseError(error);
      if (errorString !== null) {
        setError(errorString);
      }
    } finally {
      setLoadingSubmit(false);
    }
  };

  useEffect(() => {
    if (!addNew) {
      setMfaResponse(null);
      setMfaSetupResponse(null);
      setInfo("");
      setNewMethodType(undefined);
    }
  }, [addNew]);

  useEffect(() => {
    if (mfaResponse?.length === 6) {
      mfaSetupConfirm();
    }
  }, [mfaResponse]);

  if (recoveryCodes) {
    return (
      <div>
        <p className="font-semibold text-green-600">
          Your Multi-Factor Authentication (MFA) setup is now complete!
        </p>
        <br />
        Here are your recovery codes:
        <ul className="mt-2 mb-2 font-semibold">
          {recoveryCodes?.map((code) => (
            <li key={code}>{code}</li>
          ))}
        </ul>
        Please keep these codes in a safe place. They can be used to access your
        account if you ever lose access to your primary MFA methods. If you have
        any questions or need further assistance, feel free to reach out.
        <br />
        <br />
        Thank you for securing your account!
      </div>
    );
  }

  if (!newMethodType) {
    return (
      <div>
        <p className="text-gray-600 font-semibold mb-3">Select a new method</p>
        <div className="flex flex-wrap gap-2">
          {availableMethods &&
            availableMethods.map((method) => (
              <button
                key={method.id}
                onClick={() => {
                  setNewMethodType(method);
                  if (method.type === "app") {
                    mfaSetupRequestToken(method);
                  }
                }}
                className="flex flex-col gap-1 border rounded-md shadow p-1 px-2 pr-4 relative hover:scale-[1.01] w-full transition-transform duration-300"
              >
                <p className="font-semibold text-gray-700 text-start">
                  {method.title}
                </p>
                <p className="text-gray-700 text-start">{method.description}</p>
              </button>
            ))}
        </div>
      </div>
    );
  }

  if (mfaSetupResponse) {
    const mfaType = newMethodType.type!;
    return (
      <div>
        {mfaType === "sms" && (
          <div>
            <p className="text-gray-600 mb-1">SMS verification</p>
            <div>
              <p className="text-gray-600 mb-3 font-semibold">
                {
                  //@ts-ignore
                  newMethodType.instructions
                }
              </p>
              <div className="flex flex-col items-center gap-3">
                <OtpFieldInput onCompleted={(e) => setMfaResponse(e)} />
                <SubmitButton
                  onClick={mfaSetupConfirm}
                  loading={loadingSubmit}
                  className="sign-in-button !w-[280px] !h-10"
                  text="Confirm"
                />
              </div>
            </div>
          </div>
        )}
        {mfaType === "email" && (
          <div>
            <p className="text-gray-600 mb-1">Email verification</p>
            <div>
              <p className="text-gray-600 mb-3 font-semibold">
                {
                  //@ts-ignore
                  newMethodType.instructions
                }
              </p>
              <div className="flex flex-col items-center gap-3">
                <OtpFieldInput onCompleted={(e) => setMfaResponse(e)} />
                <SubmitButton
                  onClick={mfaSetupConfirm}
                  loading={loadingSubmit}
                  className="sign-in-button !w-[280px] !h-10"
                  text="Confirm"
                />
              </div>
            </div>
          </div>
        )}
        {newMethodType?.type === "app" && (
          <div>
            <p className="text-gray-600 mb-1">{newMethodType.title}</p>
            <div>
              <p className="text-gray-600 mb-3 font-semibold">
                {
                  //@ts-ignore
                  newMethodType.instructions
                }
              </p>
              <div className="flex flex-col items-center gap-3">
                {mfaSetupResponse?.imageData ? (
                  <img
                    src={mfaSetupResponse.imageData!}
                    className="w-[300px] h-[300px]"
                  />
                ) : (
                  <div className="w-[300px] h-[300px] flex justify-center items-center">
                    <ReactLoading
                      type="spinningBubbles"
                      color="#03c160"
                      height={120}
                      width={120}
                    />
                  </div>
                )}
                <OtpFieldInput onCompleted={(e) => setMfaResponse(e)} />
                <SubmitButton
                  onClick={mfaSetupConfirm}
                  loading={loadingSubmit}
                  className="sign-in-button !w-[280px] !h-10"
                  text="Confirm"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      {newMethodType.type === "sms" && (
        <div>
          <p className="text-gray-600 mb-1 font-semibold">SMS verification</p>
          <div>
            <p className="text-gray-600 mb-3 text-sm">
              Please provide your phone number so we can send you a verification
              code when you log in
            </p>
            <p className="font-semibold mb-3">Enter your phone number below:</p>
            <div className="flex flex-col items-center gap-3">
              <div className="w-[220px]">
                <PhoneInput
                  country="gb"
                  value={info}
                  onlyCountries={["gb", "us"]}
                  onChange={(phone, country, e, formattedValue) => {
                    setInfo(formattedValue);
                  }}
                  preferredCountries={["gb"]}
                  containerClass="w-[150px]"
                />
              </div>
              <SubmitButton
                onClick={() => mfaSetupRequestToken(null)}
                loading={loadingSubmit}
                className="sign-in-button !w-[220px] !h-10"
                text="Request code"
              />
            </div>
          </div>
        </div>
      )}
      {newMethodType.type === "email" && (
        <div>
          <p className="text-gray-600 mb-1">Email verification</p>
          <div>
            <p className="text-gray-600 mb-3 text-sm">
              Please provide a second email address that we can use for
              verification purposes. This will add an extra layer of security to
              your account.
            </p>
            <p className="font-semibold mb-3">
              Please enter your second email address below:
            </p>
            <div className="flex flex-col items-center gap-3">
              <input
                value={info}
                onChange={(e) => setInfo(e.target.value)}
                className="rounded shadow border border-gray-300 w-[200px] p-2 py-1"
              />
              <SubmitButton
                onClick={() => mfaSetupRequestToken(null)}
                loading={loadingSubmit}
                className="sign-in-button !w-[200px] !h-10"
                text="Request code"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
