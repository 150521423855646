import { FC, RefObject } from "react";
import ReactLoading from "react-loading";

interface SubmitButtonProps {
  onClick: () => void;
  disabled?: boolean;
  loading: boolean;
  text: string;
  className?: string;
  ref?: RefObject<HTMLButtonElement>;
}

export const SubmitButton: FC<SubmitButtonProps> = ({
  onClick,
  loading,
  text,
  className,
  disabled,
  ref
}) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={className}
    ref={ref}
    type="submit"
  >
    {loading ? (
      <ReactLoading
        type="spinningBubbles"
        color="white"
        height={40}
        width={40}
      />
    ) : (
      text
    )}
  </button>
);
