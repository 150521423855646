import "animate.css";
import { useEffect } from "react";
import { SignIn } from "./components/SignIn";
import { createClient } from "@hey-api/client-fetch";
import { ManageProfile } from "./components/profile/ManageProfile";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ChangePassword } from "./components/ChangePassword";
import { XeroSigninRedirect } from "./components/XeroSignin";
import { LinkInvitation } from "./components/LinkInvitation";
import { PasswordResetRequest } from "./components/PasswordResetRequest";
import { PageNotFound } from "./components/staticComponents/PageNotFound";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { PasswordResetResponse } from "./components/PasswordResetResponse";

const gci =
  "383150874280-f959mrco9i3rk88ugt4p318i79b7h44b.apps.googleusercontent.com";

const RedirectUrl = ({ url }: { url: string }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return <h5>Redirecting...</h5>;
};

const apiBaseUrl = () => {
  return process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL || "https://api-prod.tradepeg.com"
    : "https://api-prod.tradepeg.com";
};

createClient({
  baseUrl: apiBaseUrl(),
});

function App() {
  return (
    <>
      <GoogleOAuthProvider clientId={gci}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/signup/xero"
              element={<RedirectUrl url={XeroSigninRedirect()} />}
            ></Route>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignIn />} />
            <Route path="/forgot-password" element={<PasswordResetRequest />} />
            <Route path="/user/update-password" element={<ChangePassword />} />
            <Route
              path="/user/password-reset"
              element={<PasswordResetResponse />}
            />
            <Route path="/profile" element={<ManageProfile />} />
            <Route path="/link-invite" element={<LinkInvitation />} />
            <Route path="/404" element={<PageNotFound />} />
            <Route path="*" element={<Navigate to="/signin" />} />
          </Routes>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
