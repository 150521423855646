import { FC, useState } from "react";
import { IoWarning } from "react-icons/io5";
import { handleSuccess } from "../../utils/swal";
import { parseError } from "../../ApiClientHelper";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { SubmitButton } from "../fields/SubmitButton";
import { PasswordField } from "../fields/PasswordField";
import { AuthenticationService } from "../../api-client";
import { PasswordChecklist } from "../PasswordChecklist";

interface PasswordUpdateProps {
  handleGoBack: () => void;
  setError: (value: string | null) => void;
}

export const PasswordUpdate: FC<PasswordUpdateProps> = ({
  handleGoBack,
  setError,
}) => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [currentPassword, setCurrentPassword] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);

  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [passwordConfirmError, setPasswordConfirmError] = useState<
    string | null
  >(null);
  const [passwordCurrentError, setPasswordCurrentError] = useState<
    string | null
  >(null);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);

  const submit = async (): Promise<void> => {
    setPasswordError(null);
    setPasswordConfirmError(null);
    setPasswordCurrentError(null);
    if (password === "") {
      setPasswordError("Please enter Password");
    } else if (confirmPassword === "") {
      setPasswordConfirmError("Please confirm Password");
    } else if (currentPassword === "") {
      setPasswordCurrentError("Please enter Current Password");
    } else if (password !== confirmPassword) {
      setPasswordConfirmError("Passwords don't match");
    } else {
      try {
        setLoading(true);
        const response =
          await AuthenticationService.postAuthenticationProfilePasswordUpdate({
            headers: {
              Authorization: `${localStorage.getItem("sso_token")}`,
            },
            body: {
              password1: password,
              password2: confirmPassword,
              currentPassword,
            },
          });

        if (response.error) {
          setError(parseError(response.error));
          setLoading(false);
          return;
        }

        setComplete(true);

        handleSuccess("Password updated successfully");
      } catch (error) {
        const e = parseError(error);
        setError(e);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <button onClick={handleGoBack} className="mb-4">
        <HiArrowNarrowLeft
          size={30}
          className="text-gray-500 transform transition-transform duration-300 group-hover:scale-105"
        />
      </button>
      {!complete ? (
        <div>
          <p className="text-gray-600 mb-3 font-semibold">Update password</p>
          <p className="text-gray-600 mb-3">
            Please enter your current and new password
          </p>
          <PasswordField
            className="mb-4"
            password={currentPassword}
            handleChangePassword={function (e: string): void {
              setPasswordCurrentError(null);
              setError(null);
              setCurrentPassword(e);
            }}
            errorPassword={passwordCurrentError}
            inputLabel="Current Password"
            disableShowPassword={true}
          />
          <PasswordField
            className="mb-4"
            password={password}
            handleChangePassword={function (e: string): void {
              setPasswordError(null);
              setError(null);
              setPassword(e);
            }}
            errorPassword={passwordError}
            inputLabel="Password"
            disableShowPassword={true}
          />
          <PasswordField
            password={confirmPassword}
            handleChangePassword={function (e: string): void {
              setPasswordConfirmError(null);
              setError(null);
              setConfirmPassword(e);
            }}
            errorPassword={passwordConfirmError}
            inputLabel="Confirm Password"
            disableShowPassword={true}
          />
          {passwordError && (
            <p className="text-red-500 px-1 pb-2 pt-1 text-base flex gap-1 items-center">
              <span className="text-[20px]">
                <IoWarning />
              </span>
              {passwordError}
            </p>
          )}
          <PasswordChecklist
            password={password}
            confirmPassword={confirmPassword}
            setIsPasswordValid={setIsPasswordValid}
          />
          <SubmitButton
            onClick={submit}
            disabled={loading || !isPasswordValid}
            loading={loading}
            className="sign-in-button mt-1"
            text="Update Password"
          />
        </div>
      ) : (
        <div>
          <div className="font-semibold">
            Your password has been successfully updated
          </div>
        </div>
      )}
    </div>
  );
};
