import { ErrorResponse } from "./api-client/types.gen";

export const parseError = (error: any): string | null => {
  const prefix = "An error occurred while processing your request";

  if (error === null || error === undefined) return null;

  if (typeof error === "string") return error;

  if (error as ErrorResponse) {
    const err = error as ErrorResponse;
    return err.error || prefix;
  } else if (error && error.message) {
    return prefix + ": " + error.message;
  } else {
    return prefix;
  }
};

