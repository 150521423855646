import { MfaMethods } from "./MfaMethods";
import { UpdateProfile } from "./UpdateProfile";
import { LoginWrapper } from "../LoginWrapper";
import { useEffect, useState, FC } from "react";
import { TrustedDevices } from "./TrustedDevice";
import { PasswordUpdate } from "./PasswordUpdate";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { AuthenticationService, UserProfileBase } from "../../api-client";

export const ManageProfile = () => {
  const [error, setError] = useState<string | null>(null);
  const [hideContent, setHideContent] = useState<boolean>(true);
  const [profile, setProfile] = useState<UserProfileBase | null>(null);
  const [settingSelected, setSettingSelected] = useState<string | null>(null);
  const navigate = useNavigate();

  const token = localStorage.getItem("sso_token");

  const getProfile = async (): Promise<void> => {
    try {
      const response = await AuthenticationService.getAuthenticationProfile({
        headers: {
          Authorization: `${token}`,
        },
      });

      if (response.error || !response.data) {
        localStorage.removeItem("sso_token");
        navigate("/signin");
      }

      if (response.data) {
        setProfile(response.data.data!);
        setHideContent(false);
      }
    } catch (error: any) {
      const errorString = error.message;
      if (errorString !== null) {
        setError(errorString);
      }
    }
  };

  useEffect(() => {
    if (!token) {
      navigate("/signin");
      return;
    }
    getProfile();
  }, []);

  const handleGoBack = () => {
    setError(null);
    setSettingSelected(null);
  };

  return (
    <LoginWrapper
      title={"Manage your account"}
      error={error}
      hideContent={hideContent}
      width={
        settingSelected === "devices" || settingSelected === "methods"
          ? "w-5/6 max-w-2xl"
          : undefined
      }
    >
      {settingSelected === null && (
        <div className="flex flex-col items-start mt-6">
          <SettingButton
            onClick={() => setSettingSelected("profile")}
            label="Update profile"
            borderClasses="border-y"
          />
          <SettingButton
            onClick={() => setSettingSelected("password")}
            label="Update password"
          />
          <SettingButton
            onClick={() => setSettingSelected("methods")}
            label="Two-factor authentication"
            borderClasses="border-t"
          />
          <SettingButton
            onClick={() => setSettingSelected("devices")}
            label="Trusted devices"
            borderClasses="border-y"
          />

          <div className="flex gap-1.5 items-end w-full">
            <Link to="/signin" className="back-button mt-3">
              Back
            </Link>
            <div className="grow"></div>
          </div>
        </div>
      )}
      {settingSelected === "profile" && (
        <UpdateProfile
          profile={profile!}
          handleGoBack={handleGoBack}
          setError={setError}
          setProfile={setProfile}
        />
      )}
      {settingSelected === "password" && (
        <PasswordUpdate handleGoBack={handleGoBack} setError={setError} />
      )}
      {settingSelected === "methods" && (
        <MfaMethods handleGoBack={handleGoBack} setError={setError} />
      )}
      {settingSelected === "devices" && (
        <TrustedDevices handleGoBack={handleGoBack} setError={setError} />
      )}
    </LoginWrapper>
  );
};

interface SettingButtonProps {
  onClick: () => void;
  label: string;
  borderClasses?: string;
}

const SettingButton: FC<SettingButtonProps> = ({
  onClick,
  label,
  borderClasses,
}) => (
  <button
    onClick={onClick}
    className={`py-2 pl-1 pr-3 text-lg w-full flex justify-between items-center hover:bg-[#fcfcfc] transition duration-300 group ${borderClasses}`}
  >
    <p className="text-gray-600">{label}</p>
    <HiArrowNarrowRight
      size={30}
      className="text-gray-500 transform translate-x-2 transition-transform duration-300 group-hover:translate-x-4"
    />
  </button>
);
