import {
  ErrorResponse,
  LoginPasswordResponse,
  AuthenticationService,
  LoginPasswordMfaMethod,
  AuthResponseOrganisation,
} from "../api-client";
import Swal from "sweetalert2";
import XeroSignInButton from "./XeroSignin";
import { LoginWrapper } from "./LoginWrapper";
import { parseError } from "../ApiClientHelper";
import { MfaPopup } from "./mfa/MfaMethodInput";
import { EmailField } from "./fields/EmailField";
import { ReCAPTCHA } from "react-google-recaptcha";
import { Policy } from "./staticComponents/Policy";
import { SubmitButton } from "./fields/SubmitButton";
import { useGoogleLogin } from "@react-oauth/google";
import { PasswordField } from "./fields/PasswordField";
import { MfaMethodSelect } from "./mfa/MfaMethodSelect";
import { SelectOrganization } from "./SelectOrganization";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useState, useRef, ChangeEvent, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getDeviceFingerprint } from "../fingerprinting/Fingerprint";
import { MIN_PASSWORD_LENGTH } from "./PasswordChecklist";

export const SignIn = () => {
  const [loadingEmailLogin, setLoadingEmailLogin] = useState<boolean>(false);
  const [loadingPasswordLogin, setLoadingPasswordLogin] =
    useState<boolean>(false);

  const [organizations, setOrganizations] =
    useState<Array<AuthResponseOrganisation>>();

  const [showBilling, setShowBilling] = useState<boolean>(false);

  const signInTitle = "Sign in to TradePeg";

  const [loginError, setLoginError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [emailAddressInvalid, setEmailAddressInvalid] =
    useState<boolean>(false);
  const [title, setTitle] = useState<string>(signInTitle);

  const [password, setPassword] = useState<string>("");
  const [errorPassword, setErrorPassword] = useState<string | null>(null);

  const [requestId, setRequestId] = useState<string | null>(null);
  const [bypassCaptchaV2, setBypassCaptchaV2] = useState<boolean>(false);
  const [ssoOptions, setSsoOptions] = useState<Array<string> | null>();

  const [mfaMethods, setMfaMethods] = useState<
    Array<LoginPasswordMfaMethod> | undefined
  >(undefined);
  const [mfaMethod, setMfaMethod] = useState<
    LoginPasswordMfaMethod | undefined
  >(undefined);

  const [captchaV2, setCaptchaV2] = useState<string | null | undefined>(null);

  const captchaV2Ref = useRef<ReCAPTCHA>(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const confirmEmailButton = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();

  const performResetState = (): void => {
    setLoginError(null);
    setShowPassword(false);
    setEmailAddress("");
    setEmailAddressInvalid(false);
    setPassword("");
    setErrorPassword(null);
    setLoadingEmailLogin(false);
    setSsoOptions(null);
    setRequestId(null);
    setMfaMethods(undefined);
    setMfaMethod(undefined);
    setLoadingPasswordLogin(false);
  };

  const handleLoginError = (error: ErrorResponse | unknown | null): void => {
    setLoginError(null);
    if (error) {
      if (error as ErrorResponse) {
        const errorResponse = error as ErrorResponse;
        const resetState = errorResponse.metadata?.resetState;
        if (resetState) {
          performResetState();
        }
        if (errorResponse.error) {
          setLoginError(errorResponse.error);
        }
      } else {
        setLoginError(parseError(error));
      }
    }
  };

  const handleLoginResponse = (response: LoginPasswordResponse): void => {
    if (!response) return;

    if (response.token) {
      localStorage.setItem("sso_token", response!.token);
    }

    if (response.redirectUrl) {
      window.location.href = response.redirectUrl;
      return;
    }

    setShowBilling(response.billing!);

    if (response.organizations) {
      setOrganizations(response!.organizations!);
      return;
    }
  };

  const isSignUp = useLocation().pathname?.startsWith("/signup");

  const urlParams = new URLSearchParams(window.location.search);
  const scope = urlParams.get("scope");
  const returnUrl = urlParams.get("u");
  const returnTid = urlParams.get("tid");

  const handleGoogleResponse = async (
    googleResponseCode: string
  ): Promise<void> => {
    const deviceType = await getDeviceFingerprint();
    const payload = {
      requestId: requestId!,
      deviceId: deviceType.deviceId?.toString(),
      deviceSignature: deviceType.deviceSignature,
      provider: "google",
      autoCode: googleResponseCode,
      scope: scope,
      returnUrl: returnUrl,
      tid: returnTid,
    };

    try {
      const response = await AuthenticationService.postAuthenticationOauth({
        body: payload,
      });
      if (response.error) {
        handleLoginError(response.error);
        return;
      }
      handleLoginResponse(response.data!.data!);
    } catch (error) {
      setLoginError(parseError(error));
    }
  };

  useEffect(() => {
    setTitle(!organizations || organizations.length == 0 ? signInTitle : "");
  }, [organizations]);

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    hint: emailAddress,
    onSuccess: async (googleResponse) => {
      console.log(googleResponse);
      handleGoogleResponse(googleResponse.code);
    },
  });

  const logout = async (): Promise<void> => {
    const token = localStorage.getItem("sso_token");
    if (!token) return;

    try {
      const response = await AuthenticationService.postAuthenticationLogout({
        headers: { Authorization: token },
      });
    } catch (error) {
      console.log(error);
    } finally {
      localStorage.removeItem("sso_token");
      performResetState();
    }
  };

  const handleRemoveOrganization = async (): Promise<void> => {
    setOrganizations(undefined);
    await logout();
  };

  const tokenAuthentication = async (): Promise<void> => {
    const token = localStorage.getItem("sso_token");

    if (!token) return;

    const deviceType = await getDeviceFingerprint();

    const payload = {
      deviceId: deviceType.deviceId?.toString(),
      deviceSignature: deviceType.deviceSignature,
      returnUrl: returnUrl,
      tid: returnTid,
      scope: scope,
    };

    setLoadingEmailLogin(true);
    try {
      const response = await AuthenticationService.postAuthenticationToken({
        body: payload,
        headers: { Authorization: token },
      });

      if (response.error) {
        localStorage.removeItem("sso_token");
        return;
      }

      const newToken = response?.data?.data?.token;
      if (newToken === null || newToken === undefined || newToken === "") {
        logout();
        return;
      }

      handleLoginResponse(response.data!.data!);
    } catch (error) {
    } finally {
      setLoadingEmailLogin(false);
    }
  };

  useEffect(() => {
    tokenAuthentication();
  }, []);

  const loginEmail = async (): Promise<void> => {
    if (!isValidEmail(emailAddress)) {
      setEmailAddressInvalid(true);
      return;
    }

    setLoadingEmailLogin(true);

    try {
      let tokenV3 = null;
      if (executeRecaptcha) {
        const token = await executeRecaptcha("checkEmail");
        if (token) {
          tokenV3 = token;
        }
      }

      const deviceType = await getDeviceFingerprint();

      const response = await AuthenticationService.postAuthenticationLoginEmail(
        {
          body: {
            email: emailAddress,
            captcha: tokenV3!,
            scope: scope,
            deviceId: deviceType.deviceId?.toString(),
            deviceSignature: deviceType.deviceSignature,
            returnUrl,
            tid: returnTid,
          },
        }
      );

      if (response.error) {
        handleLoginError(response.error);
        setLoginError(parseError(response.error));
        setLoadingEmailLogin(false);
        return;
      }

      const data = response.data!.data!;

      setRequestId(data.requestId!);
      setBypassCaptchaV2(data.captchaValidated!);
      setSsoOptions(data.sso!);

      setShowPassword(data.sso === null);
    } catch (error) {
      console.log(error);
      setLoginError(parseError(error));
    } finally {
      setLoadingEmailLogin(false);
    }
  };

  const handleChangeEmail = (e: string) => {
    setEmailAddressInvalid(false);
    setEmailAddress(e);
  };

  const handleChangePassword = (value: string): void => {
    setLoginError(null);
    setErrorPassword(null);
    setPassword(value);
  };

  const onSetCaptchaV2 = (): void => {
    const recaptchaValue = captchaV2Ref.current?.getValue();
    setCaptchaV2(recaptchaValue);
  };

  const loginPassword = async (): Promise<void> => {
    setErrorPassword(null);
    setLoginError(null);
    setLoadingPasswordLogin(true);

    if (!password || !password.length) {
      setErrorPassword("Please enter password");
      setLoadingPasswordLogin(false);
      return;
    }

    if (!bypassCaptchaV2 && !captchaV2) {
      setErrorPassword("Please validate I'm not a robot");
      setLoadingPasswordLogin(false);
      return;
    }

    const deviceType = await getDeviceFingerprint();

    sessionStorage.removeItem("sso_token");

    try {
      const response =
        await AuthenticationService.postAuthenticationLoginPassword({
          body: {
            password,
            requestId: requestId!,
            captcha: captchaV2,
            deviceId: deviceType.deviceId?.toString(),
            deviceSignature: deviceType.deviceSignature,
          },
        });

      if (response.error) {
        handleLoginError(response.error);
        setLoginError(parseError(response.error));
        setLoadingEmailLogin(false);
        setLoadingPasswordLogin(false);
        return;
      }

      if (response.data?.data?.requiredAction) {
        setLoadingPasswordLogin(false);
        const { description, type } = response.data.data.requiredAction;

        if (response.data?.data?.token) {
          sessionStorage.setItem("sso_token", response.data.data.token);
        }

        Swal.fire({
          html: description!,
          icon: "warning",
          allowOutsideClick: false,
          confirmButtonText: type ? "Continue" : "Ok",
        }).then((result) => {
          if (type && type === "mfa-setup") {
            navigate("/mfa-setup");
          } else {
            setLoginError(description!);
            setLoadingPasswordLogin(false);
          }
        });
        return;
      }

      if (response.data.data?.mfa) {
        const { mfa } = response.data.data;
        setMfaMethods(mfa);
        if (mfa.length === 1) {
          setMfaMethod(mfa[0]);
        }
        return;
      }

      handleLoginResponse(response.data.data!);
    } catch (error) {
      console.log(error);
      setLoadingPasswordLogin(false);
      setLoginError(parseError(error));
    }
  };

  const handleMfaPopupBack = (): void => {
    if (mfaMethods && mfaMethods.length > 1) {
      setMfaMethod(undefined);
    } else {
      handleUseRecoveryCode();
    }
  };

  const handleBillingRedirect = (): void => {
    const token = localStorage.getItem("sso_token");
    const redirect = `https://subscription.apps.tradepeg.com/login?token=${token}`;
    window.location.href = redirect;
    return;
  };

  const handleUseRecoveryCode = (): void => {
    setMfaMethod({
      title: "Backup code",
      type: "code",
      description: "",
      notify: false,
      responseType: "text",
      responseLength: 10,
      instructions:
        "Enter your backup code to disable two-step authentication. Your backup code is the 24 character code you received when you activated two-step authentication. If you don't have your backup code, you can disable two-step authentication by contacting support.",
    });
  };

  return (
    <LoginWrapper title={title} error={loginError}>
      <div>
        {organizations || showBilling ? (
          <SelectOrganization
            showBilling={showBilling}
            organizations={organizations}
            handleRemoveOrganization={handleRemoveOrganization}
            handleBillingRedirect={handleBillingRedirect}
          />
        ) : mfaMethods && mfaMethod == undefined ? (
          <MfaMethodSelect
            mfaMethods={mfaMethods}
            setMfaType={setMfaMethod}
            requestId={requestId}
            setBack={() => handleMfaPopupBack()}
            handleUseRecoveryCode={handleUseRecoveryCode}
            handleLoginError={handleLoginError}
          />
        ) : (
          <div>
            <div className="flex flex-col items-center p-2">
              {mfaMethod ? (
                <MfaPopup
                  setLoginError={setLoginError}
                  handleLoginResponse={handleLoginResponse}
                  requestId={requestId!}
                  mfaMethod={mfaMethod}
                  mfaMethods={mfaMethods!}
                  back={handleMfaPopupBack}
                />
              ) : (
                <div className="w-full">
                  <EmailField
                    emailAddress={emailAddress}
                    emailAddressInvalid={emailAddressInvalid}
                    handleChangeEmail={handleChangeEmail}
                    showEdit={showPassword || ssoOptions != null}
                    handleEdit={performResetState}
                    handleEnter={loginEmail}
                  />
                  {showPassword ? (
                    <div>
                      <PasswordField
                        password={password}
                        handleChangePassword={handleChangePassword}
                        errorPassword={errorPassword}
                        autoFocus={true}
                        minLength={MIN_PASSWORD_LENGTH}
                        handleEnter={loginPassword}
                      />
                      {!bypassCaptchaV2 && (
                        <div className="my-3">
                          <ReCAPTCHA
                            ref={captchaV2Ref}
                            sitekey="6LcM6RkTAAAAACJvWc5MZAydMPA7DyTrKg0NQLfc"
                            onChange={onSetCaptchaV2}
                          />
                        </div>
                      )}
                      <div>
                        <Link
                          to={"/forgot-password"}
                          className="underline text-blue-600 text-[14px] font-semibold "
                        >
                          Forgot your password?
                        </Link>
                        <SubmitButton
                          onClick={loginPassword}
                          disabled={loadingPasswordLogin}
                          loading={loadingPasswordLogin}
                          className="sign-in-button mt-3"
                          text="Log in"
                        />
                      </div>
                    </div>
                  ) : (
                    ssoOptions == null && (
                      <SubmitButton
                        ref={confirmEmailButton}
                        onClick={loginEmail}
                        disabled={loadingEmailLogin}
                        loading={loadingEmailLogin}
                        className="sign-in-button mt-1"
                        text="Continue"
                      />
                    )
                  )}
                </div>
              )}
              <Policy />
            </div>
            {(ssoOptions?.includes("google") || isSignUp) && (
              <div>
                <div className="mt-3">
                  <button
                    onClick={() => googleLogin()}
                    type="button"
                    className="shadow-[0_4px_16px_0_rgba(18,28,45,0.2)] p-3 w-full border gap-2 border-slate-200 rounded-lg text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow transition duration-150 flex justify-center"
                  >
                    <img
                      className="w-6 h-6"
                      src="https://www.svgrepo.com/show/475656/google-color.svg"
                      loading="lazy"
                      alt="google logo"
                    />
                    <span>
                      {isSignUp
                        ? "Sign up with Google"
                        : "Continue login with Google"}
                    </span>
                  </button>
                </div>
              </div>
            )}

            {isSignUp && (
              <div>
                <div className="mt-3">
                  <XeroSignInButton label="Sign up with Xero" />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </LoginWrapper>
  );
};

export const isValidEmail = (email: string) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};
