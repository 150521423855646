import {
  LoginPasswordResponse,
  AuthenticationService,
  LoginPasswordMfaMethod,
} from "../../api-client";
import { parseError } from "../../ApiClientHelper";
import { SubmitButton } from "../fields/SubmitButton";
import { useEffect, useState, useRef, FC } from "react";
import { OtpFieldInput } from "../fields/OtpFieldInput";
import { getDeviceFingerprint } from "../../fingerprinting/Fingerprint";

interface Props {
  setLoginError: (error: string) => void;
  handleLoginResponse: (response: LoginPasswordResponse) => void;
  requestId: string;
  mfaMethod: LoginPasswordMfaMethod;
  mfaMethods: Array<LoginPasswordMfaMethod>;
  back: () => void;
}

export const MfaPopup: FC<Props> = ({
  setLoginError,
  handleLoginResponse,
  requestId,
  mfaMethod,
  mfaMethods,
  back,
}) => {
  const [trustDevice, setTrustDevice] = useState<boolean>(false);
  const confirmMfaButton = useRef<HTMLButtonElement>(null);
  const [loadingMfa, setLoadingMfa] = useState<boolean>(false);
  const [mfaResponse, setMfaResponse] = useState<string>();

  useEffect(() => {
    setLoginError("");
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        confirmMfaButton.current?.click();
      }
      document.addEventListener("keydown", handleKeyPress);

      return () => {
        document.removeEventListener("keydown", handleKeyPress);
      };
    };
  }, []);

  const isMfaResponseValid = (): boolean => {
    return (
      (mfaMethod.responseType === "6digit" && mfaResponse?.length === 6) ||
      (mfaMethod.responseType === "text" &&
        mfaResponse?.length === mfaMethod.responseLength)
    );
  };

  useEffect(() => {
    if (isMfaResponseValid() && trustDevice) {
      handleMfaConfirmation();
    }
  }, [mfaResponse, setMfaResponse]);

  const handleMfaConfirmation = async (): Promise<void> => {
    if (!isMfaResponseValid()) {
      setLoginError("Please enter a valid response");
      return;
    }
    setLoadingMfa(true);
    const deviceType = await getDeviceFingerprint();

    try {
      const response = await AuthenticationService.postAuthenticationLoginMfa({
        body: {
          requestId: requestId,
          type: mfaMethod.type!,
          response: mfaResponse!,
          trustDevice,
          deviceId: deviceType.deviceId?.toString(),
          deviceSignature: deviceType.deviceSignature,
        },
      });
      setLoadingMfa(false);

      if (response.error) {
        setLoginError(parseError(response.error)!);
        return;
      }
      debugger;

      handleLoginResponse(response.data?.data!);
    } catch (error: any) {
      console.log(error);
      const prefix = "An error occurred while processing your request";
      if (error && error.message) {
        setLoginError(prefix + ": " + error.message);
      } else {
        setLoginError(prefix);
      }
    } finally {
      setLoadingMfa(false);
    }
  };

  return (
    <div>
      <div className="text-center rounded-md border-slate-400">
        <p className="text-gray-800 mb-2 p-1 text-base w-full text-start">
          {mfaMethod.instructions}
        </p>

        {true && (
          <div className="flex gap-2 items-center mt-1.5 mb-4">
            <input
              id="trustDevice"
              type="checkbox"
              checked={trustDevice}
              onChange={(e) => setTrustDevice(e.target.checked)}
              className="shadow border-[1px] border-[#cccccc] hover:border-[#b3b3b3] h-5 w-5 rounded p-2 focus:ring-0 focus:outline-none"
            />
            <label
              htmlFor="trustDevice"
              className="text-sm text-gray-800 bg-white peer-focus:text-blue-600"
            >
              Trust this device
            </label>
          </div>
        )}

        {mfaMethod.responseType === "6digit" ? (
          <OtpFieldInput onCompleted={(e) => setMfaResponse(e)} />
        ) : (
          <input
            type="text"
            className="rounded-md shadow border-gray-300 w-full"
            value={mfaResponse}
            maxLength={mfaMethod?.responseLength}
            onChange={(e) => {
              setMfaResponse(e.target.value);
            }}
          />
        )}
      </div>
      <div className="flex">
        <SubmitButton
          ref={confirmMfaButton}
          onClick={handleMfaConfirmation}
          disabled={loadingMfa}
          loading={loadingMfa}
          className="sign-in-button mt-1"
          text="Continue"
        />
      </div>

      {mfaMethods?.length > 1 && (
        <div className="pt-2 pb-2 flex justify-end">
          <button className="back-button" onClick={back}>
            Back
          </button>
        </div>
      )}

      {mfaMethods?.length <= 1 && mfaMethod.type !== "code" && (
        <div className="pt-2 pb-2 flex justify-end">
          <button
            onClick={back}
            className="text-sm font-semibold text-blue-500"
          >
            I don't have access to this device
          </button>
        </div>
      )}
    </div>
  );
};
