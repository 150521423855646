import Swal from "sweetalert2";
import { useEffect, useState, FC } from "react";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { parseError } from "../../ApiClientHelper";
import { FaTrash, FaLocationDot } from "react-icons/fa6";
import { AuthenticationService, TrustedDevice } from "../../api-client";
import { getDeviceFingerprint } from "../../fingerprinting/Fingerprint";
import { handleError, handleSuccess, swalStyle } from "../../utils/swal";

interface TrustedDevicesProps {
  handleGoBack: () => void;
  setError: (value: string | null) => void;
}

export const TrustedDevices: FC<TrustedDevicesProps> = ({
  handleGoBack,
  setError,
}) => {
  const [mfaDevices, setMfaDevices] = useState<
    TrustedDevice[] | null | undefined
  >(undefined);

  const getTrustedDevices = async (): Promise<void> => {
    const deviceType = await getDeviceFingerprint();

    try {
      const response =
        await AuthenticationService.getAuthenticationProfileMfaTrustedDevices({
          headers: {
            Authorization: `${localStorage.getItem("sso_token")}`,
          },
          query: {
            deviceId: deviceType.deviceId || undefined,
            deviceSignature: deviceType.deviceSignature || undefined,
          },
        });

      if (response.error) {
        setError(parseError(response.error));
        return;
      }
      setMfaDevices(response.data.results);
    } catch (error: any) {
      const errorString = parseError(error);
      if (errorString !== null) {
        setError(errorString);
      }
    }
  };

  const handleDeleteTrustedDevice = async (
    id: string | null | undefined
  ): Promise<void> => {
    try {
      if (!id) {
        handleError("Device id is missing");
        return;
      }
      const response =
        await AuthenticationService.deleteAuthenticationProfileMfaTrustedDevicesDeviceid(
          {
            headers: {
              Authorization: `${localStorage.getItem("sso_token")}`,
            },
            path: {
              deviceId: id,
            },
          }
        );

      if (response.error) {
        setError(parseError(response.error));
        return;
      }

      handleSuccess("Device deleted successfully");
      await getTrustedDevices();
    } catch (error: any) {
      const errorString = parseError(error);
      if (errorString !== null) {
        setError(errorString);
      }
    }
  };

  const confirmDeleteTrustedDevice = (id: string | null | undefined): void => {
    if (!id) {
      setError("Device id is missing");
      return;
    }
    Swal.fire({
      title: `Are you sure you want to remove this device?`,
      showCancelButton: true,
      position: "top",
      cancelButtonText: "Confirm",
      confirmButtonText: "Cancel",
      allowEnterKey: false,
      ...swalStyle,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.close();
      } else if (result.isDismissed) {
        handleDeleteTrustedDevice(id);
      }
    });
  };

  function formatDateTime(
    dateString: string | Date | undefined | null
  ): string {
    if (!dateString) return "";

    const date =
      typeof dateString === "string" ? new Date(dateString) : dateString;

    if (date.getTimezoneOffset() !== 0) {
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    }

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    };

    return date.toLocaleString("en-GB", options);
  }

  useEffect(() => {
    getTrustedDevices();
  }, []);

  return (
    <div>
      <button onClick={handleGoBack} className="mb-2">
        <HiArrowNarrowLeft
          size={30}
          className="text-gray-500 transform transition-transform duration-300 group-hover:scale-105"
        />
      </button>
      <p className="text-gray-600 mb-3 font-semibold">Trusted Devices</p>
      <p className="text-gray-600 mb-3 text-sm">
        The following devices are currently trusted and do not require
        multi-factor authentication.
      </p>
      <div className="flex flex-col gap-1.5">
        {mfaDevices &&
          mfaDevices.map((device) => (
            <div
              key={device.id}
              className="flex flex-col gap-1 border rounded-md shadow p-1 px-2 pr-4 relative"
            >
              <p className="font-semibold">
                {device.thisDevice && (
                  <span className="bg-emerald-600 text-white rounded-md p-1 text-xs me-3">
                    This device
                  </span>
                )}
                {device.name}
              </p>
              <p className="flex gap-1.5 items-center">
                {device.location}
                <FaLocationDot color="green" />
              </p>
              {device.createdTime && (
                <p>Trusted: {formatDateTime(device.createdTime)}</p>
              )}
              {device.lastUsedTime && (
                <p>Last used: {formatDateTime(device.lastUsedTime)}</p>
              )}
              <button
                className="absolute right-3 bottom-3"
                onClick={() => confirmDeleteTrustedDevice(device.id)}
              >
                <FaTrash className="text-red-500" />
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};
