import { FC } from "react";
import { clsx } from "clsx";
import type { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { OTPInput, SlotProps } from "input-otp";

function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

interface Props {
  onCompleted?: (otp: string) => void;
  className?: string | undefined;
}

export const OtpFieldInput: FC<Props> = ({ onCompleted, className }) => {
  return (
    <OTPInput
      maxLength={6}
      className={className}
      textAlign="center"
      inputMode="numeric"
      containerClassName="group flex items-center justify-center has-[:disabled]:opacity-30"
      onComplete={onCompleted}
      render={({ slots }) => (
        <>
          <div className="flex">
            {slots.slice(0, 3).map((slot, idx) => (
              <Slot key={idx} {...slot} />
            ))}
          </div>

          <FakeDash />

          <div className="flex">
            {slots.slice(3).map((slot, idx) => (
              <Slot key={idx} {...slot} />
            ))}
          </div>
        </>
      )}
    />
  );

  // Feel free to copy. Uses @shadcn/ui tailwind colors.
  function Slot(props: SlotProps) {
    return (
      <div
        className={cn(
          "relative w-10 h-14 text-[2rem]",
          "flex items-center justify-center",
          "transition-all duration-300",
          "border-border border-y border-r first:border-l first:rounded-l-md last:rounded-r-md",
          "group-hover:border-emerald-600/20 group-focus-within:border-emerald-600/20",
          "outline outline-0 outline-emerald-600/20",
          { "outline-1 outline-emerald-600": props.isActive }
        )}
      >
        {props.char !== null && <div>{props.char}</div>}
        {props.hasFakeCaret && <FakeCaret />}
      </div>
    );
  }

  // You can emulate a fake textbox caret!
  function FakeCaret() {
    return (
      <div className="absolute pointer-events-none inset-0 flex items-center justify-center animate-caret-blink">
        <div className="w-px h-8 bg-emerald-600" />
      </div>
    );
  }

  // Inspired by Stripe's MFA input.
  function FakeDash() {
    return (
      <div className="flex w-10 justify-center items-center">
        <div className="w-3 h-1 rounded-full bg-emerald-600" />
      </div>
    );
  }
};
