import { isValidEmail } from "./SignIn";
import { Link } from "react-router-dom";
import { useState, useRef } from "react";
import { LoginWrapper } from "./LoginWrapper";
import ReCAPTCHA from "react-google-recaptcha";
import { parseError } from "../ApiClientHelper";
import { EmailField } from "./fields/EmailField";
import { SubmitButton } from "./fields/SubmitButton";
import { AuthenticationService } from "../api-client";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export const PasswordResetRequest = () => {
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [emailAddressInvalid, setEmailAddressInvalid] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [description, setDescription] = useState<string | null>(
    "To receive a link to reset your password, please enter your email address"
  );

  const [showCaptcha, setShowCaptcha] = useState<boolean>(false);
  const [captchaV2, setCaptchaV2] = useState<string | null | undefined>(null);

  const captchaV2Ref = useRef<ReCAPTCHA>(null);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSetCaptchaV2 = (): void => {
    const recaptchaValue = captchaV2Ref.current?.getValue();
    setCaptchaV2(recaptchaValue);
  };

  const submit = async (): Promise<void> => {
    setLoading(true);
    setEmailAddressInvalid(false);

    if (!isValidEmail(emailAddress)) {
      setEmailAddressInvalid(true);
      setLoading(false);
    } else {
      try {
        let captcha = null;
        if (captchaV2) {
          captcha = captchaV2;
        } else if (executeRecaptcha) {
          const token = await executeRecaptcha("submit");
          if (token) {
            captcha = token;
          } else {
            setShowCaptcha(true);
            setError("Please validate you are not a robot");
            return;
          }
        } else {
          setShowCaptcha(true);
          setError("Please validate you are not a robot");
          return;
        }

        const response =
          await AuthenticationService.postAuthenticationPasswordResetRequest({
            body: {
              email: emailAddress,
              captcha,
            },
          });

        if (response.error) {
          setError(parseError(response.error));
          setLoading(false);
        }

        const captchaRetry = response.error?.metadata?.captchaRetry;

        if (captchaRetry) {
          setShowCaptcha(true);
          setLoading(false);
          return;
        }
        setDescription(null);
        setComplete(true);
      } catch (error) {
        setError(parseError(error));
        setLoading(false);
        return;
      }
    }
    setLoading(false);
  };

  return (
    <LoginWrapper
      title={"Reset your password"}
      error={error}
      description={description}
    >
      <div className="w-full">
        {complete && (
          <div>
            <p className="text-gray-500 font-normal text-start">
              <span className="text-lg font-semibold text-black">
                Check your email
              </span>
              <br />
              <br />
              If there is an account associated with{" "}
              <strong className="font-semibold text-black">
                {emailAddress}
              </strong>{" "}
              you’ll get an email with a link to reset your password.
              <br />
              <br />
              If you do not receive an email within a few minutes, please check
              your spam or junk folder.
              <br />
              <br />
              The link will expire after 1 hour.
            </p>

            <Link
              to={"/signIn"}
              className="!bg-emerald-500 sign-in-button mt-4  "
            >
              Back to log in
            </Link>
          </div>
        )}

        {!complete && (
          <div>
            <div className="w-full">
              <EmailField
                emailAddress={emailAddress}
                handleChangeEmail={(e) => {
                  setEmailAddressInvalid(false);
                  setEmailAddress(e);
                }}
                emailAddressInvalid={emailAddressInvalid}
                showEdit={false}
                handleEdit={() => {}}
              />
            </div>
            {showCaptcha && (
              <div>
                <div className="mt-5"></div>
                <ReCAPTCHA
                  ref={captchaV2Ref}
                  sitekey="6LcM6RkTAAAAACJvWc5MZAydMPA7DyTrKg0NQLfc"
                  onChange={onSetCaptchaV2}
                />
                <div className="mt-3"></div>
              </div>
            )}
            <SubmitButton
              onClick={submit}
              loading={loading}
              disabled={loading}
              className="sign-in-button mt-1"
              text="Reset Password"
            />
            <p className="mt-4">
              <Link to={"/signIn"} className="back-button-white">
                Return to log in
              </Link>
            </p>
          </div>
        )}
      </div>
    </LoginWrapper>
  );
};
