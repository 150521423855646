import { FC } from "react";
import { IoWarning } from "react-icons/io5";
import { isValidEmail } from "../SignIn";

interface EmailFieldProps {
  emailAddress: string | null;
  emailAddressInvalid: boolean;
  handleChangeEmail: (e: string) => void;
  showEdit: boolean;
  handleEdit: () => void;
  handleEnter?: () => void;
}

export const EmailField: FC<EmailFieldProps> = ({
  emailAddress,
  emailAddressInvalid,
  handleChangeEmail,
  showEdit,
  handleEdit,
  handleEnter,
}) => {
  return (
    <div>
      <div className="relative mt-1">
        <input
          type="email"
          required
          id="email"
          name="email"
          placeholder=" "
          value={emailAddress || ""}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (handleEnter) {
                if (isValidEmail(emailAddress!)) {
                  handleEnter();
                }
              }
            }
          }}
          onChange={(e) => {
            handleChangeEmail(e.target.value);
          }}
          disabled={showEdit}
          className={`${
            emailAddressInvalid ? "border-red-500" : "border-gray-[#7c879f]"
          } border-gray-400 0 mb-3 bg-transparent rounded-md border-1.5 focus:border-blue-600 peer block shadow leading-10
           w-full text-md text-gray-800 disabled:opacity-50 focus:outline-none focus:ring-0 appearance-none`}
        />
        {showEdit && (
          <span className="absolute top-4 right-5">
            <button className="underline text-blue-600" onClick={handleEdit}>
              Edit
            </button>
          </span>
        )}
        <label
          htmlFor="email"
          className={`absolute text-md duration-300 transform -translate-y-[20px] translate-x-[5px] scale-75 top-2 z-10 origin-[0] bg-white px-1 ${
            emailAddressInvalid ? "text-red-500" : "text-gray-500"
          } peer-focus:px-1 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2
            peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-[20px] peer-focus:translate-x-[5px] left-1`}
        >
          Email address
        </label>
      </div>
      {emailAddressInvalid ? (
        <p className="text-red-500 px-1 pb-2 text-base flex gap-1 items-center">
          <span className="text-[20px]">
            <IoWarning />
          </span>
          <span>Email is invalid</span>
        </p>
      ) : (
        <div></div>
      )}
    </div>
  );
};
