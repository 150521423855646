export const Policy = () => {
  return (
    <div>
      <p className="text-gray-400 text-xs mt-5">
        This site is protected by reCAPTCHA and the Google&nbsp;
        <a href="https://policies.google.com/privacy" target="_blank" className="underline">
          {" "}
          Privacy Policy{" "}
        </a>
        &nbsp;and&nbsp;
        <a href="https://policies.google.com/terms" target="_blank" className="underline">
          Terms of Service
        </a>
        &nbsp;apply
      </p>
    </div>
  );
};
