import {
  AuthenticationService,
  ErrorResponse,
  LoginPasswordMfaMethod,
} from "../../api-client";
import { FC, useState } from "react";
import ReactLoading from "react-loading";
import { getDeviceFingerprint } from "../../fingerprinting/Fingerprint";

export interface Props {
  mfaMethods: LoginPasswordMfaMethod[];
  setMfaType: (mfaType: LoginPasswordMfaMethod) => void;
  setBack: () => void;
  requestId: string | null;
  handleUseRecoveryCode: () => void;
  handleLoginError: (error: ErrorResponse | unknown | null) => void;
}

export const MfaMethodSelect: FC<Props> = ({
  mfaMethods,
  requestId,
  setMfaType,
  setBack,
  handleUseRecoveryCode,
  handleLoginError,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleNotify = async (
    option: LoginPasswordMfaMethod
  ): Promise<void> => {
    if (option.notify) {
      setLoading(true);
      handleLoginError(null);

      const deviceType = await getDeviceFingerprint();

      try {
        const response =
          await AuthenticationService.postAuthenticationLoginMfaNotify({
            body: {
              requestId: requestId!,
              type: option.type!,
              deviceId: deviceType.deviceId?.toString(),
              deviceSignature: deviceType.deviceSignature,
            },
          });

        setLoading(false);

        if (response.error) {
          console.log(response.error);
          handleLoginError(response.error);
          return;
        }
      } catch (error) {
        handleLoginError(error);
        setLoading(false);
      }
    }
    setMfaType(option);
  };

  return (
    <div>
      <div>
        <h2 className="text-lg font-semibold w-full p-2">
          Two-step authentication
        </h2>
        <h3 className="text-sm font-normal w-full p-2">
          To continue, please select a two-step authentication method
        </h3>
      </div>

      {loading && (
        <div className="flex justify-center">
          <ReactLoading type="balls" color="#000" height={20} width={20} />
        </div>
      )}
      <div className="max-h-[350px] overflow-y-auto flex flex-wrap gap-2 justify-between py-2">
        {mfaMethods.map((option) => {
          return (
            <button
              disabled={loading}
              key={option.type}
              onClick={() => handleNotify(option)}
              className="p-3 m-1 ps-6 border rounded shadow cursor-pointer w-full text-left text-sm disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <p className="font-semibold text-base mb-2">{option.title}</p>
              {option.description}
            </button>
          );
        })}
      </div>

      <div className="p-2 flex place-content-between">
        <button
          onClick={handleUseRecoveryCode}
          className="text-sm font-semibold text-blue-500"
        >
          I don't have any of these
        </button>

        <button className="back-button" onClick={setBack}>
          Back
        </button>
      </div>
    </div>
  );
};
