import { Footer } from "./footer";
import { Link } from "react-router-dom";

export const PageNotFound = () => {
  return (
    <div className="h-[100vh] flex justify-center items-center  bg-[#edf1fa]">
      <div className="flex flex-col justify-center items-center p-6 bg-white rounded-md">
        <p className="text-[95px] text-[#d8e2ef] font-bold">404</p>

        <Link
          to={"/signIn"}
          className="bg-blue-600 p-2 text-white rounded-md cursor-pointer disabled:opacity-70 mt-5"
        >
          Home
        </Link>
      </div>
      <Footer />
    </div>
  );
};
