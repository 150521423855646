import { FC } from "react";
import { Footer } from "./staticComponents/footer";

interface LoginWrapperProps {
  title: string;
  error: string | null;
  children: React.ReactNode;
  description?: string | null;
  hideContent?: boolean;
  width?: string;
}

export const LoginWrapper: FC<LoginWrapperProps> = ({
  title,
  error,
  children,
  description,
  hideContent,
  width = "w-[92%] sm:w-[420px]",
}) => {
  return (
    <div className="login-container">
      <div
        className={`${width} rounded-md px-6 py-8 sm:px-10 sm:py-12 bg-white shadow-[0_4px_16px_0_rgba(18,28,45,0.2)]`}
      >
        {error && (
          <p className="bg-[#fff2ea] border-orange-500 w-full text-center text-red-500 p-3 mb-2 rounded-md border-2 font-semibold">
            {error}
          </p>
        )}
        {!hideContent && (
          <div>
            <h1 className="login-title">{title}</h1>
            <div className="login-content">
              <img
                src="https://cdn.tradepeg.com/assets/img/logo/logo-tradepeg.png"
                alt="logo"
                className="max-w-24 mb-4"
              />
              <div className="w-full">
                {description && (
                  <p className="text-gray-600 mb-3">{description}</p>
                )}
                {children}
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};
