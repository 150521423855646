export const Footer = () => {
  return (
    <div className="absolute bottom-0 hidden md:block">
      <p className="text-white mb-2 text-sm">
        Powered by{" "}
        <a
          className="underline"
          href="https://www.tradepeg.com"
          target="_blank"
        >
          TradePeg
        </a>
        &nbsp;© {new Date().getFullYear()}&nbsp; | &nbsp;
        <a
          className="underline"
          href="https://www.tradepeg.com/privacy-policy"
          target="_blank"
        >
          Privacy Policy
        </a>
      </p>
    </div>
  );
};
