import { IoWarning } from "react-icons/io5";
import { useEffect, useState } from "react";
import { LoginWrapper } from "./LoginWrapper";
import { parseError } from "../ApiClientHelper";
import { SubmitButton } from "./fields/SubmitButton";
import { AuthenticationService } from "../api-client";
import { PasswordField } from "./fields/PasswordField";
import { PasswordChecklist } from "./PasswordChecklist";
import { useSearchParams, Link, useNavigate } from "react-router-dom";

export const PasswordResetResponse = () => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);
  const [hideContent, setHideContent] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [passwordConfirmError, setPasswordConfirmError] = useState<
    string | null
  >(null);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");

  if (token === null) {
    navigate("/", { replace: true });
  }

  const validateToken = async (): Promise<void> => {
    try {
      const response =
        await AuthenticationService.postAuthenticationPasswordResetResponse({
          body: {
            token: token!,
            password1: "validation",
            password2: "validation",
          },
        });

      if (response.error) {
        setError(parseError(response.error));
        setHideContent(true);
        return;
      }
    } catch (error) {
      const e = parseError(error);
      setError(e);
      setHideContent(true);
      return;
    }
  };

  useEffect(() => {
    validateToken();
  }, []);

  const submit = async (): Promise<void> => {
    setLoading(true);
    setPasswordError(null);
    setPasswordConfirmError(null);
    if (password === "") {
      setPasswordError("Please enter Password");
    } else if (confirmPassword === "") {
      setPasswordConfirmError("Please confirm Password");
    } else if (password !== confirmPassword) {
      setPasswordConfirmError("Passwords don't match");
    } else {
      try {
        const response =
          await AuthenticationService.postAuthenticationPasswordResetResponse({
            body: {
              token: token!,
              password1: password,
              password2: confirmPassword,
            },
          });

        if (response.error) {
          setError(parseError(response.error));
          setLoading(false);
          return;
        }

        setComplete(true);
      } catch (error) {
        const e = parseError(error);
        setError(e);
        setLoading(false);
        return;
      }
    }
    setLoading(false);
  };

  return (
    <LoginWrapper
      title={"Reset your password"}
      error={error}
      hideContent={hideContent}
    >
      {!complete && (
        <div>
          <p className="text-gray-600 mb-3">Please enter your new password</p>
          <PasswordField
            className="mb-4"
            password={password}
            handleChangePassword={function (e: string): void {
              setPasswordError(null);
              setError(null);
              setPassword(e);
            }}
            errorPassword={passwordError}
            inputLabel="Password"
            disableShowPassword={true}
          />
          <PasswordField
            password={confirmPassword}
            handleChangePassword={function (e: string): void {
              setPasswordConfirmError(null);
              setError(null);
              setConfirmPassword(e);
            }}
            errorPassword={passwordConfirmError}
            inputLabel="Confirm Password"
            disableShowPassword={true}
          />
          {passwordError && (
            <p className="text-red-500 px-1 pb-2 pt-1 text-base flex gap-1 items-center">
              <span className="text-[20px]">
                <IoWarning />
              </span>
              {passwordError}
            </p>
          )}
          <PasswordChecklist
            password={password}
            confirmPassword={confirmPassword}
            setIsPasswordValid={setIsPasswordValid}
          />
          <SubmitButton
            onClick={submit}
            disabled={loading || !isPasswordValid}
            loading={loading}
            className="sign-in-button mt-1"
            text="Reset Password"
          />
        </div>
      )}

      {complete && (
        <div>
          <div>
            Your password has been reset successfully. Please log in with your
            new password.
          </div>
          <Link className="sign-in-button mt-2" to={"/signin"}>
            Log in
          </Link>
        </div>
      )}
    </LoginWrapper>
  );
};
