import { FC, useEffect } from "react";

interface PasswordChecklistProps {
  password: string;
  confirmPassword: string;
  setIsPasswordValid: (value: boolean) => void;
}

export const MIN_PASSWORD_LENGTH = 12;

export const PasswordChecklist: FC<PasswordChecklistProps> = ({
  password,
  confirmPassword,
  setIsPasswordValid,
}) => {
  const isLongEnough = password.length >= MIN_PASSWORD_LENGTH;
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumber = /[0-9]/.test(password);
  const hasSpecialChar = /[^A-Za-z0-9]/.test(password);
  const passwordsMatch = password === confirmPassword && password.length > 0;

  useEffect(() => {
    const isPasswordValid =
      isLongEnough &&
      hasUppercase &&
      hasLowercase &&
      hasNumber &&
      hasSpecialChar &&
      passwordsMatch;
    setIsPasswordValid(isPasswordValid);
  }, [
    isLongEnough,
    hasUppercase,
    hasLowercase,
    hasNumber,
    hasSpecialChar,
    passwordsMatch,
    setIsPasswordValid,
  ]);

  const checked = (
    <svg viewBox="0 0 512 512" fill="currentColor" height="1.2em" width="1.2em">
      <path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7l233.4-233.3c12.5-12.5 32.8-12.5 45.3 0z" />
    </svg>
  );
  const xMark = (
    <svg viewBox="0 0 320 512" fill="currentColor" height="1.2em" width="1.2em">
      <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3l105.4 105.3c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256l105.3-105.4z" />
    </svg>
  );

  return (
    <ul className="pl-1 pb-1 text-sm">
      <li
        className={`${
          isLongEnough ? "text-green-500" : "text-red-500"
        } flex items-center gap-0.5`}
      >
        {isLongEnough ? checked : xMark} At least {MIN_PASSWORD_LENGTH}{" "}
        characters
      </li>
      <li
        className={`${
          hasUppercase && hasLowercase ? "text-green-500" : "text-red-500"
        } flex items-center gap-0.5`}
      >
        {hasUppercase && hasLowercase ? checked : xMark} Includes upper and
        lower case letters
      </li>
      {/*       <li
        className={`${
          hasLowercase ? "text-green-500" : "text-red-500"
        } flex items-center gap-0.5`}
      >
        {hasLowercase ? checked : xMark} At least one lowercase letter
      </li> */}
      <li
        className={`${
          hasNumber && hasSpecialChar ? "text-green-500" : "text-red-500"
        } flex items-center gap-0.5`}
      >
        {hasNumber && hasSpecialChar ? checked : xMark} Includes numbers and
        special characters
      </li>
      {/*      <li
        className={`${
          hasSpecialChar ? "text-green-500" : "text-red-500"
        } flex items-center gap-0.5`}
      >
        {hasSpecialChar ? checked : xMark} At least one special character
      </li> */}
      <li
        className={`${
          passwordsMatch ? "text-green-500" : "text-red-500"
        } flex items-center gap-0.5`}
      >
        {passwordsMatch ? checked : xMark} Passwords match
      </li>
    </ul>
  );
};
