import Swal from "sweetalert2";
import { useState } from "react";
import { LoginWrapper } from "./LoginWrapper";
import { handleResponse } from "../utils/swal";
import { parseError } from "../ApiClientHelper";
import { SubmitButton } from "./fields/SubmitButton";
import { AuthenticationService } from "../api-client";
import { PasswordField } from "./fields/PasswordField";
import { PasswordChecklist } from "./PasswordChecklist";
import { useNavigate, Link, useSearchParams } from "react-router-dom";

export const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);
  const [currentPasswordError, setCurrentPasswordError] = useState<
    string | null
  >(null);
  const [passwordError1, setPasswordError1] = useState<string | null>(null);
  const [passwordError2, setPasswordError2] = useState<string | null>(null);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);

  const submit = async (): Promise<void> => {
    setLoading(true);
    setCurrentPasswordError(null);
    setPasswordError1(null);
    setPasswordError2(null);
    if (currentPassword === "") {
      setCurrentPasswordError("Please enter current Password");
    } else if (password === "") {
      setPasswordError1("Please enter Password");
    } else if (confirmPassword === "") {
      setPasswordError2("Please confirm Password");
    } else if (password !== confirmPassword) {
      setPasswordError2("Passwords don't match");
    } else {
      try {
        const response =
          await AuthenticationService.postAuthenticationProfilePasswordUpdate({
            headers: {
              Authorization: localStorage.getItem("sso_token"),
            },
            body: {
              currentPassword,
              password1: password,
              password2: confirmPassword,
            },
          });

        if (response.error) {
          setError(parseError(response.error));
          setLoading(false);
          return;
        }

        Swal.fire({
          html: "Your password has been updated successfully.",
          icon: "success",
          allowOutsideClick: false,
          confirmButtonText: "Go to log in",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/", { replace: true });
          }
        });
      } catch (error) {
        handleResponse(
          "Something went wrong with your request, please try again. If the problem persist contact us",
          "error"
        );
        console.log(error);
        setLoading(false);
        return;
      }
    }
    setLoading(false);
  };

  return (
    <LoginWrapper
      title={"Reset your password"}
      error={error}
      description={"Please enter your new password"}
    >
      <div className="w-full">
        <PasswordField
          className="mb-4"
          password={currentPassword}
          handleChangePassword={function (e: string): void {
            setCurrentPasswordError(null);
            setError(null);
            setCurrentPassword(e);
          }}
          errorPassword={currentPasswordError}
          inputLabel="Current Password"
          disableShowPassword={true}
        />

        <PasswordField
          className="mb-4"
          password={password}
          handleChangePassword={function (e: string): void {
            setPasswordError1(null);
            setError(null);
            setPassword(e);
          }}
          errorPassword={passwordError1}
          inputLabel="Password"
          disableShowPassword={true}
        />

        <PasswordField
          password={confirmPassword}
          handleChangePassword={function (e: string): void {
            setPasswordError2(null);
            setError(null);
            setConfirmPassword(e);
          }}
          errorPassword={passwordError2}
          inputLabel="Confirm Password"
          disableShowPassword={true}
        />

        <PasswordChecklist
          password={password}
          confirmPassword={confirmPassword}
          setIsPasswordValid={setIsPasswordValid}
        />

        <SubmitButton
          onClick={submit}
          disabled={loading || !isPasswordValid}
          loading={loading}
          className="sign-in-button mt-1"
          text="Reset Password"
        />

        <p className="mt-4">
          <Link to={"/signIn"} className="back-button-white">
            Return to log in
          </Link>
        </p>
      </div>
    </LoginWrapper>
  );
};
