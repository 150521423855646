import Swal, { SweetAlertIcon } from "sweetalert2";

export const handleResponse = (text: string, type: SweetAlertIcon) => {
  Swal.fire({
    html: text,
    icon: type,
    allowOutsideClick: false,
    confirmButtonText: "Continue",
  });
};

export const handleError = (text: string) => {
  Swal.fire({
    title: "Something went wrong",
    text,
    position: "top",
    confirmButtonText: "Close",
    ...swalStyle,
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.close();
    }
  });
};

export const handleSuccess = (
  title: string,
  refresh: boolean = false
): void => {
  Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1500,
    customClass: {
      title: "!w-fit",
      popup: "!w-fit",
    },
  })
    .fire({
      icon: "success",
      title,
    })
    .then((result) => {
      if (result.isConfirmed) {
        if (refresh) {
          window.location.reload();
        }
        Swal.close();
      }
    });
};

export const swalStyle = {
  allowOutsideClick: false,
  backdrop: false,
  customClass: {
    popup: "pb-2 w-fit",
    modal: "w-fit bg-red-500",
    actions: "!mt-0",
    confirmButton: " !bg-gray-500 border-none !shadow  focus:!shadow py-1",
    cancelButton: "!bg-red-500 border-none !shadow focus:!shadow py-1",
    title: "text-xl font-normal pt-2 px-4 ",
    htmlContainer: " !text-gray-500 !text-base !mt-1 mb-0",
  },
  showClass: {
    popup: "animate__animated animate__slideInDown animate__faster",
  },
  hideClass: {
    popup: "animate__animated animate__fadeOutUp animate__faster",
  },
};
