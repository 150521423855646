import { FC } from "react";
import { Link } from "react-router-dom";
import { FaMoneyBill1, FaUser } from "react-icons/fa6";
import { MdLogout } from "react-icons/md";
import { AuthResponseOrganisation } from "../api-client";

interface SelectOrganizationProps {
  organizations: Array<AuthResponseOrganisation> | null | undefined;
  showBilling: boolean;
  handleRemoveOrganization: () => void;
  handleBillingRedirect: () => void;
}

export const SelectOrganization: FC<SelectOrganizationProps> = ({
  organizations,
  showBilling,
  handleRemoveOrganization,
  handleBillingRedirect,
}) => {
  return (
    <div>
      <div className="bg-white rounded-md p-4 flex-wrap gap-2">
        <div className="max-h-[350px] overflow-y-auto flex flex-wrap gap-2 justify-between py-2">
          {organizations?.map((org) => {
            return (
              <a
                key={org.organization!.id}
                href={org.redirect!}
                className="p-2 border rounded flex gap-2 items-center shadow cursor-pointer w-full"
              >
                <img src={org.organization!.logo!} className="h-10 rounded" />
                <p className="w-full truncate"> {org.organization!.name}</p>
              </a>
            );
          })}
        </div>

        <div className="flex gap-1.5 items-end w-full">
          <Link
            to="/profile"
            className="manage-profile-button mt-3 flex items-center"
          >
            <FaUser className="inline mr-2" />
            Manage profile
          </Link>
          <div className="grow"></div>
          <button
            onClick={handleRemoveOrganization}
            className="sign-out-button mt-3 flex items-center"
          >
            <MdLogout size={15} className="inline mr-2" />
            Logout
          </button>
        </div>

        {showBilling && (
          <div className="flex gap-1.5 items-end w-full mt-3">
            <button
              onClick={handleBillingRedirect}
              className="manage-billing-button w-[100%] mt-2 mb-2"
            >
              <FaMoneyBill1 className="inline mr-2" />
              Manage Billing & Subscription
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
