import {
  InviteProfile,
  InviteStatusResponse,
  AuthenticationService,
} from "../api-client";
import { useEffect, useState } from "react";
import { LoginWrapper } from "./LoginWrapper";
import { parseError } from "../ApiClientHelper";
import { PasswordField } from "./fields/PasswordField";
import { PasswordChecklist } from "./PasswordChecklist";
import { useSearchParams, useNavigate } from "react-router-dom";

export const LinkInvitation = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();

  const [inviteStatus, setInviteStatus] = useState<InviteStatusResponse>();
  const [inviteProfile, setInviteProfile] = useState<InviteProfile | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);
  const [password1, setPassword1] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string | null>(null);

  const [loading1, setLoading1] = useState<boolean>(false);
  const [loading2, setLoading2] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);

  const getInviteLinkStatus = async (): Promise<void> => {
    try {
      setError(null);
      if (!token) {
        return;
      }
      setLoading1(true);

      const response =
        await AuthenticationService.postAuthenticationInviteStatus({
          body: { token },
        });

      if (response.error) {
        setError(parseError(response.error));
        return;
      }

      const { data } = response;

      setInviteStatus(response.data?.data);
      if (data?.data?.profileRequired) {
        setInviteProfile({
          nameFirst: data?.data?.profile?.nameFirst || "",
          nameLast: data?.data?.profile?.nameLast || "",
          password1: "",
          password2: "",
        });
      }
      setLoading1(false);
    } catch (error) {
      const e = parseError(error);
      setError(e);
      setLoading1(false);
    }
  };

  const processInvite = async (): Promise<void> => {
    try {
      setError(null);
      setLoading2(true);
      if (!token) {
        return;
      }

      inviteProfile!.password1 = password1;
      inviteProfile!.password2 = password2;

      var response =
        await AuthenticationService.postAuthenticationInviteProcess({
          body: {
            token,
            accept: true,
            profile: inviteProfile!,
          },
        });

      if (response.error) {
        setError(parseError(response.error));
        return;
      }

      setComplete(true);
      setTimeout(() => {
        navigate("/", { replace: true });
      }, 1500);
    } catch (error) {
      const e = parseError(error);
      setError(e);
    } finally {
      setLoading2(false);
    }
  };

  useEffect(() => {
    if (!token) {
      setError("Invalid invite link");
    }
    getInviteLinkStatus();
  }, []);

  return (
    <LoginWrapper title="Invitation" error={error} hideContent={loading1}>
      <div>
        <p className="text-gray-700 text-center">
          {inviteStatus?.inviteDescription}
        </p>
        {inviteProfile && (
          <div className="mt-4">
            <div className="flex gap-2">
              <div className="relative mb-2">
                <input
                  value={inviteProfile.nameFirst}
                  onChange={(e) => {
                    inviteProfile.nameFirst = e.target.value;
                  }}
                  type="text"
                  id="firstName"
                  placeholder=" "
                  className={`block shadow leading-10 w-full text-md text-gray-900 bg-transparent rounded-md border-1.5 border-gray-[#7c879f] appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                />
                <label
                  htmlFor="firstName"
                  className={`absolute text-md text-gray-500 duration-300 transform -translate-y-[20px] translate-x-[5px] scale-75 top-2 z-10 origin-[0] bg-white px-1 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-[20px] peer-focus:translate-x-[5px] left-1`}
                >
                  First Name
                </label>
              </div>
              <div className="relative mb-2">
                <input
                  value={inviteProfile.nameLast}
                  onChange={(e) => {
                    inviteProfile.nameLast = e.target.value;
                  }}
                  type="text"
                  id="lastName"
                  placeholder=" "
                  className={`block shadow leading-10 w-full text-md text-gray-900 bg-transparent rounded-md border-1.5 border-gray-[#7c879f] appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer`}
                />

                <label
                  htmlFor="lastName"
                  className={`absolute text-md text-gray-500 duration-300 transform -translate-y-[20px] translate-x-[5px] scale-75 top-2 z-10 origin-[0] bg-white px-1  peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-[20px] peer-focus:translate-x-[5px] left-1`}
                >
                  Last Name
                </label>
              </div>
            </div>
            <div className="flex gap-2 mt-3">
              <PasswordField
                className="mb-4"
                password={password1}
                handleChangePassword={function (e: string): void {
                  setPasswordError(null);
                  setError(null);
                  setPassword1(e);
                }}
                errorPassword={passwordError}
                inputLabel="Password"
                disableShowPassword={true}
              />
              <PasswordField
                password={password2}
                handleChangePassword={function (e: string): void {
                  setPasswordError(null);
                  setError(null);
                  setPassword2(e);
                }}
                errorPassword={passwordError}
                inputLabel="Confirm Password"
                disableShowPassword={true}
              />
            </div>
            <PasswordChecklist
              password={password1}
              confirmPassword={password2}
              setIsPasswordValid={setIsPasswordValid}
            />
          </div>
        )}

        {inviteStatus && (
          <button
            disabled={
              error !== null ||
              (inviteStatus && inviteStatus.profileRequired && !isPasswordValid)
            }
            onClick={processInvite}
            className="sign-in-button mt-3"
          >
            Accept invitation
          </button>
        )}
      </div>
    </LoginWrapper>
  );
};
